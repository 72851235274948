import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import userApi from 'src/utils/axios';
import ConfigData from './../../../../../config/constant.json'

import {
  CCardBody,
  CCol,
  CRow,
  CModalHeader,
  CModal,
  CFormTextarea,
  CInputGroup,
  CModalTitle,
  CModalBody,
  CForm,
  CFormInput,
  CButton,
} from '@coreui/react';

const AddBatchForum = ({ visible, onClose, addToaster, courseId ,Batch_id}) => {
    
  // Formik setup
  const formik = useFormik({
    initialValues: {
      topicName: '',
      description: '',
      file: null,
    },
    validationSchema: Yup.object({
      topicName: Yup.string().required('Topic name is required'),
      description: Yup.string().required('Description is required'),
      file: Yup.mixed(),
    }),
    onSubmit: async (values, { resetForm }) => {
        try {
          const formData = new FormData();
          formData.append('topicName', values.topicName);
          formData.append('description', values.description);
          formData.append('file', values.file);
          formData.append('courseId', courseId);
          formData.append('batchId', JSON.stringify(Batch_id));
  
          // Call backend API
          const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/addTopic`, formData);
  
          if (response.data.status === false) {
            throw new Error(response.data.message);
          }
  
          addToaster({ status: true, body: 'Forum Added', message: 'Success' });
          onClose();
          resetForm();
        } catch (error) {
          addToaster({ status: false, body: error.message, message: 'Failed' });
        }
      },
    
  });

  const handleFileChange = (event) => {
    formik.setFieldValue('file', event.currentTarget.files[0]);
  };


  return (
    <CModal visible={visible} alignment="center" onClose={onClose} backdrop="static">
      <CModalHeader onClose={onClose}>
        <CModalTitle>Add Forum</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CCardBody>
          <CForm onSubmit={formik.handleSubmit}>
            
            <CRow className="mb-3">
              <CCol md={12}>
                <CInputGroup>
                  <CFormInput
                    type="text"
                    id="topicName"
                    name="topicName"
                    placeholder="Enter Topic Name"
                    value={formik.values.topicName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik.touched.topicName && Boolean(formik.errors.topicName)}
                  />
                </CInputGroup>
                {formik.touched.topicName && formik.errors.topicName && (
                  <small className="text-danger">{formik.errors.topicName}</small>
                )}
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md={12}>
                <CFormTextarea
                  id="description"
                  name="description"
                  placeholder="Enter Description"
                  rows="4"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.description && Boolean(formik.errors.description)}
                ></CFormTextarea>
                {formik.touched.description && formik.errors.description && (
                  <small className="text-danger">{formik.errors.description}</small>
                )}
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol md={12}>
                <CFormInput
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.file && Boolean(formik.errors.file)}
                />
                {formik.touched.file && formik.errors.file && (
                  <small className="text-danger">{formik.errors.file}</small>
                )}
              </CCol>
            </CRow>

            <CRow>
              <CCol md={12} className="text-end">
                <CButton color="success" type="submit">
                  Save
                </CButton>
                <CButton color="secondary" className="ms-2" onClick={onClose}>
                  Cancel
                </CButton>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CModalBody>
    </CModal>
  );
};

export default AddBatchForum;

import React from "react";
import { useHistory } from "react-router-dom";
import userApi from "src/utils/axios";
import LoadingScreen from 'src/views/shared/Loading';
import ConfigData from './../../../../config/constant.json'
import {
    CCard, CCardBody, CCol, CRow, CCardTitle, CButton, CAccordion, CAccordionItem, CAccordionHeader,
    CAccordionBody, CTable, CTableBody, CTableRow, CTableHead, CTableHeaderCell, CTableDataCell
} from '@coreui/react'
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react'
import { cilPencil, cilMinus } from '@coreui/icons'
import DeleteDailog from "../DeleteDailog";
import Toaster from 'src/views/shared/Toaster';
import AddBatchModule from "./BatchModule/AddBatchModule";
import BatchAddVideo from "./BatchVideo.js/BatchAddVideo";
import EditBatchModule from "./BatchModule/EditBatchModule";
import BatchResources from "./BatchResources";
import BatchEditVideo from "./BatchVideo.js/BatchEditVideo";
import CourseResources from "../Module/CourseResources";
import moment from 'moment';
import Page500 from "../../page500/Page500";
import AddBatchForum from "./BatchForum/BatchForumForm";
import BatchForum from "./BatchForum/BatchForum";

const BatchDetails = (props) => {
    if (!props.location.state) {
        return <Page500></Page500>
    }
    const [BatchDetails, setBatchDetails] = React.useState(false);
    const [deleteVideoDailog, setDeleteVideoDailog] = React.useState(false);
    const [editModalState, setEditModalState] = React.useState(false);
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [moduleId, setModuleId] = React.useState([]);
    const [videoId, setVideoId] = React.useState([]);
    const [addModuleState, setAddModuleState] = React.useState(false);
    const [addForumState, setAddForumState] = React.useState(false);
    const [showModuleTable, setShowModuleTable] = React.useState(true);
    const [moduleIndex, setModuleIndex] = React.useState(null)
    const [videoForm, setVideoForm] = React.useState(false);
    const [teacherData, setTeacherData] = React.useState(null)
    const [editModal, setEditModal] = React.useState(false)
    const [videoDetails, setVideoDetails] = React.useState(null)
    const [videoIndex, setVideoIndex] = React.useState(null)
    const [selectedUser, setSelectedUser] = React.useState({});
    const [addResources, setAddResources] = React.useState(false);
    const [resourceModal, setResourceModal] = React.useState(false);
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const Batch_id = props.location.state.batchId
    const courseId = props.location.state.courseId
    const batchName = props.location.state.batchName
    const instituteId = props.location.state.instituteId
    const instituteName = props.location.state.instituteName
    const published = props.location.state.published
    const [role, setRole] = React.useState(true);


    let history = useHistory();

    React.useEffect(() => {
        getBatchDetails();
    }, [])

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    const getBatchDetails = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getbatchdetails?batchId=${Batch_id}`)
            if (response.data.status === true) {
                setBatchDetails(response.data.data)
                setTeacherData(response.data.teachers)
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    // Delete module 
    const handleDelete = async () => {
        try {

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/deletebatchmodule`, { moduleId })


            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setBatchDetails(BatchDetails.filter((item) => { return item.module._id !== moduleId._id }))
            setDeleteDailog(false)
        } catch (e) {
            console.log(e)
        }

    }
    // Delete module with all associated data
    const handleAllDelete = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/deletebatchmodulealldata`, { moduleId })


            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setBatchDetails(BatchDetails.filter((item) => { return item.module._id !== moduleId._id }))
            setDeleteDailog(false)

        } catch (e) {
            console.log(e)

        }


    }
    // Delete video route
    const handleDeleteVideo = async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/deletebatchvideo`, { videoId })
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            setBatchDetails(BatchDetails.map((element) => { return { ...element, videos: element.videos.filter((video) => video._id !== videoId._id) } }))
            setDeleteVideoDailog(false)
        } catch (e) {
            console.log(e)
        }

    }

    let deleteModule = (option) => {
        setModuleId(option)
        setDeleteDailog(true)
    }

    let deleteVideo = (item) => {
        setDeleteVideoDailog(true)
        setVideoId(item)
    }

    // Add module Callback
    let addModuleCallBack = (newmodule) => {
        let data = { module: { ...newmodule }, videos: [] }
        setBatchDetails(batchData => [...batchData, data])
        setAddModuleState(false)
    }

    // Add video from function
    const handleVideoForm = (moduleId, index) => {
        setModuleId(moduleId)
        setShowModuleTable(false)
        setVideoForm(true)
        setModuleIndex(index)
    }
    // update video array
    const addVideoCallBack = (newvideo) => {
        let tempArray = [...BatchDetails]
        let newVideos = [...BatchDetails[moduleIndex].videos, newvideo]
        tempArray[moduleIndex].videos = newVideos
        setBatchDetails(tempArray)
    }

    let editModule = (option, index) => {
        setModuleId(option)
        setModuleIndex(index)
        setEditModalState(true)
    }


    let editModuleCallBack = (editedModule, index) => {
        let tempArray = [...BatchDetails]
        let tempElement = { ...BatchDetails[moduleIndex].module, ...editedModule }
        tempArray[moduleIndex].module = tempElement
        setBatchDetails(tempArray)
        setEditModalState(false)
    }

    const callBackVideos = async (videos) => {
        setSelectedUser(videos);
        setAddResources(!addResources)

    }

    let editVideo = (item, index, index2) => {
        setEditModal(true)
        setVideoDetails(item)
        setModuleIndex(index)
        setVideoIndex(index2)
    }

    let editVideoCallBack = (editedVideo) => {
        let tempArray = [...BatchDetails]
        let tempElement = { ...BatchDetails[moduleIndex].videos[videoIndex], ...editedVideo }
        tempArray[moduleIndex].videos[videoIndex] = tempElement
        setBatchDetails(tempArray)
        setEditModal(false)
    }

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    const viewResources = (item) => {
        setVideoId(item._id)
        setResourceModal(true)
    }

    return (<>
        <CRow>
            <CCol sm={12}>
                <CCard style={{ background: "white" }}>
                    <CCardBody>
                        <CRow>
                            <CCol sm={1}><CButton onClick={() => history.push({ pathname: '/pages/master/module', state: { courseId: courseId, published: published, instituteId: instituteId, instituteName: instituteName, activekey: 2 } })} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Back</CButton></CCol>
                            <CCol sm={7}> <CCardTitle style={{ color: "black", textAlign: 'center' }}>{batchName}</CCardTitle></CCol>
                            {
                                role &&
                                <CCol sm={2}> <CButton onClick={() => setAddForumState(true)} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Add Forum</CButton></CCol>
                            }
                            {
                                role &&
                                <CCol sm={2}> <CButton onClick={() => setAddModuleState(true)} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Add Module</CButton></CCol>
                            }
                            {
                                addModuleState &&
                                <AddBatchModule
                                    visible={addModuleState}
                                    onClose={() => setAddModuleState(false)}
                                    addToaster={addToaster}
                                    addModuleCallBack={addModuleCallBack}
                                    courseId={courseId}
                                    Batch_id={Batch_id}
                                >
                                </AddBatchModule>
                            }

{
                                addForumState &&
                                <AddBatchForum
                                    visible={addForumState}
                                    onClose={() => setAddForumState(false)}
                                    addToaster={addToaster}
                                    addModuleCallBack={addModuleCallBack}
                                    courseId={courseId}
                                    Batch_id={Batch_id}
                                >
                                </AddBatchForum>
                            }

                            {
                                editModalState &&
                                <EditBatchModule
                                    visible={editModalState}
                                    onClose={() => setEditModalState(false)}
                                    addToaster={addToaster}
                                    modalId={moduleId}
                                    editModuleCallBack={editModuleCallBack}
                                    courseId={courseId}
                                >
                                </EditBatchModule>
                            }

                            {
                                deleteDailog && <DeleteDailog
                                    visible={deleteDailog}
                                    onClose={() => setDeleteDailog(false)}
                                    delete={handleDelete}
                                    allDelete={handleAllDelete}
                                ></DeleteDailog>
                            }
                            {/* curent changes */}
                            {
                                addResources &&
                                <BatchResources
                                    visible={addResources}
                                    onClose={() => setAddResources(false)}
                                    Batch_id={Batch_id}
                                    currentUser={selectedUser}
                                    addToaster={addToaster}>
                                </BatchResources>
                            }
                            {
                                editModal &&
                                <BatchEditVideo
                                    visible={editModal}
                                    onClose={() => setEditModal(false)}
                                    addToaster={addToaster}
                                    videoDetails={videoDetails}
                                    editVideoCallBack={editVideoCallBack}
                                    teacherData={teacherData}
                                >
                                </BatchEditVideo>
                            }
                            {
                                resourceModal &&
                                <CourseResources // location   /pages/Master/Module/CourseResources
                                    visible={resourceModal}
                                    onClose={() => setResourceModal(false)}
                                    courseCompo='batchCompo'
                                    video_id={videoId}>
                                </CourseResources>
                            }
                            {
                                deleteVideoDailog && <DeleteDailog
                                    visible={deleteVideoDailog}
                                    onClose={() => setDeleteVideoDailog(false)}
                                    delete={handleDeleteVideo}
                                ></DeleteDailog>
                            }
                            {
                                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>

                            }
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <br></br>
        {!videoForm && <h3>Available Batch Modules:-</h3>}
        {videoForm && <div style={{ marginTop: "2%" }}>
            <CRow className="justify-content-center">
                <CCol md={11}>
                    <h3>Add Videos:-</h3>
                </CCol>
                <CCol md={1}>
                    <button className="btn btn-outline-danger" style={{ width: '100%' }} title='back to module list' onClick={() => { setShowModuleTable(true), setVideoForm(false) }} >Back</button>
                </CCol>
            </CRow>
        </div>}
        <br></br>
        {
            videoForm &&
            <>
                <br />
                <BatchAddVideo
                    visible={videoForm}
                    onClose={() => setVideoForm(false)}
                    addToaster={addToaster}
                    addVideoCallBack={addVideoCallBack}
                    courseId={courseId}
                    moduleId={moduleId}
                    teacherData={teacherData}
                    Batch_id={Batch_id}
                />
            </>
        }
        {
            loading && <LoadingScreen></LoadingScreen>
        }

        {
            BatchDetails && showModuleTable && BatchDetails.map((option, index) => (

                <CAccordion flush key={index} width={"100%"}>
                    <CAccordionItem itemKey={1} >
                        <CAccordionHeader style={{ border: '1px solid black' }}>

                            <div style={{ flex: 1 }}>

                                {option.module.module_serial_number} {option.module.module_name.substring(0, 50)}{option.module.module_name.length > 50 ? "..." : ""}
                            </div>
                            {
                                role &&
                                <div style={{ marginRight: 10 }} title='Add videos in Module' onClick={() => handleVideoForm(option.module._id, index)} className="btn btn-outline-primary">Add Video</div>

                            }


                            {/* <Link style={{ textDecoration: "none", marginRight: 10 }} className="btn btn-outline-primary"
                                to={{
                                    pathname: '/pages/master/batchquiz',
                                    state: { batch_id: Batch_id, courseId: courseId, moduleId: option.module._id, moduleName: option.module.module_name, teacherData: teacherData, visible: true }
                                }}>
                                View Quiz
                            </Link> */}
                            {
                                role &&
                                <>
                                    <CIcon style={{ marginRight: 10, color: 'blue' }} onClick={() => editModule(option.module, index)} title='Edit Module' size='lg' icon={cilPencil} />
                                    <CIcon style={{ marginRight: 10, color: 'red' }} onClick={() => deleteModule(option.module)} title='Delete Module' size='xxl' icon={cilMinus} />

                                </>
                            }
                        </CAccordionHeader>
                        <div className='container'>
                            {option.videos &&
                                <CAccordionBody key={index}>
                                    <div style={{ overflow: "auto" }}>
                                        <CTable striped hover >
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell scope="col">Sr. No</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">original name</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Taught By</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Schedule Videos</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Resources</CTableHeaderCell>
                                                    {
                                                        role &&
                                                        <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                                                    }

                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody>
                                                {
                                                    option.videos.length > 0 && option.videos.map((item, index2) => (
                                                        <CTableRow key={item._id}>
                                                            <CTableDataCell  >{item.sr_no}</CTableDataCell>
                                                            <CTableDataCell  >{item.title}</CTableDataCell>
                                                            <CTableDataCell  >{item.originalName}</CTableDataCell>
                                                            <CTableDataCell  >{item.description}</CTableDataCell>
                                                            {item.teacher_id == null ? <CTableDataCell  >null</CTableDataCell> : <CTableDataCell  >{item.teacher}</CTableDataCell>}
                                                            <CTableDataCell  >{moment(item.scheduleDate).format('DD/MM/YYYY || HH:mm A')}</CTableDataCell>
                                                            <CTableDataCell onClick={() => viewResources(item)} style={{ color: 'blue', cursor: 'pointer' }} ><u>View</u></CTableDataCell>

                                                            {role && <CTableDataCell  >
                                                                <button onClick={() => callBackVideos(item)} className="btn btn-outline-primary">Add Resourses</button> &nbsp;&nbsp;
                                                                <button onClick={() => editVideo(item, index, index2)} className="btn btn-outline-primary">Edit</button> &nbsp;&nbsp;
                                                                <button onClick={() => deleteVideo(item)} className="btn btn-outline-primary" >Delete</button>
                                                            </CTableDataCell>}
                                                        </CTableRow>
                                                    ))
                                                }
                                            </CTableBody>
                                        </CTable>
                                    </div>

                                </CAccordionBody>}

                            {option.videos == 0 &&
                                <CAccordionBody > No Videos are Added</CAccordionBody>
                            }
                        </div>
                    </CAccordionItem>

                </CAccordion>

            ))

        }
        {
            !loading && BatchDetails.length == 0 && !error && <h1> No Batch Module Added</h1>
        }
        {
            !loading && error && <h1>{error}</h1>
        }
        {
            <BatchForum Batch_id={Batch_id}/>
        }

    </>);
}

export default BatchDetails;
import { CButton, CCard, CCardBody, CCardTitle, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CNav, CNavItem, CNavLink, CRow } from '@coreui/react/dist/index.js';
import React from 'react';
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import ConfigData from 'src/config/constant.json'
import userApi from 'src/utils/axios'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import DeleteDailog from '../../Master/DeleteDailog';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ExcelFile from 'src/views/shared/ExcelFile';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react'



const CouponCodeGenerate = () => {
  const [loading, setLoading] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState(1);
  const [visible, setVisible] = React.useState(false)
  const [deleteVisible, setDeleteVisible] = React.useState(false)
  const [toast, addToast] = React.useState(false)
  const [usedCouponData, setUsedCouponData] = React.useState([]);
  const [deleteDailog, setDeleteDialog] = React.useState(false);
  const [usedCouponDeleteDailog, setUsedCouponDeleteDialog] = React.useState(false);
  const [updatedStatus, setUpdatedStatus] = React.useState('');
  const [endDateValue, setEndDateValue] = React.useState('');
  const [updatedLotId, setUpdatedLotId] = React.useState('');
  const [createCouponPage, setCreateCouponPage] = React.useState(true);
  const [lotData, setLotData] = React.useState([]);
  const [viewUsedCouponData, setViewUsedCouponData] = React.useState([]);
  const [viewPage, setViewPage] = React.useState('');
  const [role, setRole] = React.useState(true);
  const [coupon, setCoupon] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tab, setTab] = React.useState(false);

  // Pagination Logic
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [pageCount, setPageCount] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  const [debouncedValue, setDebouncedValue] = React.useState('');
  let timeoutId; // Declare timeoutId variable outside of debounce function

  // Fetch coupon data when debounced search term changes
  React.useEffect(() => {
    if (debouncedValue !== '') {
      fetchUsedCoupons(debouncedValue);
    } else {
      fetchUsedCoupons('');
    }

  }, [debouncedValue]);

  // Fetch coupon data on initial load
  React.useEffect(() => {
    fetchUsedCoupons('');
  }, [])

  // Fetch coupon data when offset or filterBy changes
  React.useEffect(() => {
    fetchUsedCoupons('');
  }, [offset])

  let addToaster = (toast) => {
    addToast(toast)
    setTimeout(() => {
      addToast(false)
    }, 3000)
  }

  React.useEffect(() => {
    const userRoles = localStorage.getItem("role");
    if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
      setRole(false);
    } else {
      setRole(true);
    }
  }, [])





  // Function to fetch all coupons
  const fetchAllCoupons = async () => {
    try {
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getAllCouponCodes`);
      if (response.data.data) {
        setLoading(false);
        setCouponData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };


  // Function to get all the used coupon data
  const fetchUsedCoupons = async (searchTerm) => {

    try {
      setLoading(true);
      const searchCoupon = searchTerm ? searchTerm : "";
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getLotCoupons?searchTerm=${searchCoupon}&offset=${offset}`);
      if (response.data.status == true) {
        setLoading(false);
        setUsedCouponData(response.data.data.couponData);
        setTotalCount(response.data.data.couponDataCount);
        setPageCount(Math.ceil(response.data?.data?.couponDataCount / itemsPerPage))

      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  // Function to get all the lot names data
  const fetchAllLotNames = async () => {
    try {
      const searchCoupon = searchTerm ? searchTerm : "";
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getLotCoupons?searchTerm=${searchCoupon}&offset=${offset}`);
      if (response.data.data) {

        setLoading(false);
        setUsedCouponData(response.data.data.couponData);
        setTotalCount(response.data.data.couponDataCount);
        setLotData(response.data.data.couponData);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  React.useEffect(async () => {
    await fetchAllLotNames();
  }, []);

  React.useEffect(async () => {
    fetchUsedCoupons();
    setViewPage('');
  }, [activeKey])

  const handleUsedCouponDeleteCoupon = async () => {
    try {
      const response = await userApi.delete(`${ConfigData.SERVER_URL}/admin/coupons/deleteUserFromCoupon?couponId=${usedCouponCodeId}&userId=${usedCouponUserId}&courseId=${usedCouponCourseId}`)
      if (response.data.status == true) {
        setUsedCouponDeleteDialog(false);
        fetchUsedCoupons();
        fetchAllCoupons();
        addToaster({ status: true, message: "Success", body: response.data.message });
      } else {
        addToaster({ status: false, message: "Failure", body: response.data.message });
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Handle page change
  const handlePageClickCoupon = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const handlePageClickLot = (e) => {
    setOffset(e.selected);
  }
  const handlePageClick = (e) => {
    setOffset(e.selected)
  }

  const handleEditLot = async (lotId, lotData) => {
    setVisible(!visible)
    setUpdatedLotId(lotId);
    setUpdatedStatus(lotData.status);
    setEndDateValue(lotData.endDate);
  }

  const handleDeleteLot = async (lotId) => {
    setUpdatedLotId(lotId);
    setDeleteVisible(true);
  }

  const deleteLotData = async () => {
    try {
      const response = await userApi.delete(`${ConfigData.SERVER_URL}/admin/coupons/deleteLot?lotId=${updatedLotId}`)
      if (response.data.status == true) {
        closeDeleteModel();
        fetchAllLotNames();
        addToaster({ status: true, message: "Success", body: response.data.message });
      } else {
        addToaster({ status: false, message: "Failure", body: response.data.message });
      }
    } catch (error) {
      console.log(error)
    }
  }


  // Calculate start and end indexes for pagination
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = viewUsedCouponData.slice(startIndex, endIndex);
  // Handle search
  const handleViewCouponSearch = (value) => {
    setSearchTerm(value);
    if (value) {
      const filteredData = coupon.filter(row =>
        row.usedBy.length > 0 && row.usedBy.some(user =>
          JSON.parse(user).name.toLowerCase().includes(value.toLowerCase())
        )
      );
      setViewUsedCouponData(filteredData);
      setCurrentPage(0); // Reset to first page when searching
    } else {
      setViewUsedCouponData(coupon);
      setCurrentPage(0);
    }
  };

  const handleLotSearch = (e) => {
    debounce(e.target.value, 500);
  }


  // Handle search input change with debounce
  const handleCouponSearch = (e) => {
    debounce(e.target.value, 500);
  }

  // Debounce function to delay search input
  const debounce = (value, delay) => {

    clearTimeout(timeoutId); // Clear the previous timeout if exists

    timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup function to clear the timeout on unmount or new debounce call
    return () => clearTimeout(timeoutId);
  }

  const closeUpdateModal = async () => {
    setVisible(!visible);
  }

  const closeDeleteModel = async () => {
    setDeleteVisible(false);
  }

  const handleStatusChange = (event) => {
    setUpdatedStatus(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDateValue(event.target.value);
  };

  //Handle excel file 
  const handleExcelFile = async () => {
    try {
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getLotExcelData`)
      if (response.data.status == true) {
        return response.data.data
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const handleCouponExcelFile = async () => {
    try {
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getCouponExcelData`)
      if (response.data.status == true) {
        return response.data.data
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  const updateLot = async () => {
    setLoading(true);
    const response = await userApi.put(`${ConfigData.SERVER_URL}/admin/coupons/updateLot?lotId=${updatedLotId}&endDate=${endDateValue}&updatedStatus=${updatedStatus}`)
    if (response.data.status == true) {
      closeUpdateModal();
      fetchAllLotNames();
      setUpdatedStatus('');
      setEndDateValue('');
      addToaster({ status: true, message: "Success", body: response.data.message });
    } else {
      addToaster({ status: false, message: "Failure", body: response.data.message });
    }
  }

  const handleViewUsedCoupons = async (usedCoupon) => {
    setViewPage('Used');
    setCurrentPage(0)
    setViewUsedCouponData(usedCoupon);
    setCoupon(usedCoupon);
  }



  const handleViewUnusedCoupons = async (unusedCoupon) => {
    setViewPage('Unused');
    setViewUnusedCouponData(unusedCoupon);
  }
  const history = useHistory();

  return (
    <>
      <CRow>
        <CCol sm={12} className='pb-4'>
          <CCard style={{ background: "white" }}>
            <CCardBody>
              <CRow>
                <CCol sm={5}>
                  <>
                    {tab && <CButton onClick={() => { history.goBack(); setTab(false) }} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >Back</CButton>}
                    {!tab && <CButton style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} onClick={() =>
                      history.push({ pathname: '/pages/analytics' })
                    } >Back</CButton>}
                  </>
                </CCol>
                <CCol sm={5}>
                  <CCardTitle style={{ color: "black" }}>Coupon Code</CCardTitle>
                </CCol>
                <CCol sm={2} className="d-flex justify-content-end">
                  {
                    role &&
                    <CButton
                      style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }}
                      onClick={() => {
                        history.push({ pathname: '/pages/analytics/createCoupon' });
                        setTab(true);
                      }}
                    >
                      Add Coupon Code
                    </CButton>}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow >

      {createCouponPage &&
        <CCard>
          <CCardHeader>
            <CNav variant="underline" layout="fill">
              <CNavItem  >
                <CNavLink
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                    fontWeight: activeKey === 1 ? '700' : '500',
                    fontSize: activeKey === 1 ? '20px' : '18px',
                  }}
                  active={activeKey === 1}
                  onClick={() => { setActiveKey(1), setOffset(0) }}
                >
                  Lot Data
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  style={{
                    cursor: 'pointer',
                    color: 'black',
                    borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                    fontWeight: activeKey === 2 ? '700' : '500',
                    fontSize: activeKey === 2 ? '20px' : '18px',
                  }}
                  active={activeKey === 2}
                  onClick={() => { setActiveKey(2), setOffset(0) }}
                >
                  Coupons
                </CNavLink>
              </CNavItem>
            </CNav>
          </CCardHeader>
          {activeKey == 1 ? (
            <CCardBody>
              <CRow>
                <h5>Total Lot count: <span ><b>{totalCount}</b></span></h5>
              </CRow>
              <CRow>
                <CCol md={6} >

                  <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                    <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                      <CIcon icon={cilSearch} size="xl" />
                    </div>

                    <CFormInput
                      placeholder="Search..."
                      autoComplete="Search"
                      style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                      onChange={handleLotSearch}
                    />
                  </div>
                </CCol>
                <CCol md={4}></CCol>
                <CCol md={2}>
                  <div style={{ marginTop: '-1%' }}>
                    <ExcelFile data={handleExcelFile} />
                  </div>
                </CCol>
              </CRow>
              <br />
              {loading && <LoadingScreen></LoadingScreen>}

              {!loading &&
                <>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Sr. No</th>
                        <th style={{ textAlign: "center" }}>Lot Name</th>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th style={{ textAlign: "center" }}>Type</th>
                        <th style={{ textAlign: "center" }}>Start Date</th>
                        <th style={{ textAlign: "center" }}>End Date</th>
                        {
                          role &&
                          <th style={{ textAlign: "center" }}>Action</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        usedCouponData.map((lot, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>{offset * 10 + (index + 1)}</td>
                            <td style={{ textAlign: "center" }}>{lot.lotName}</td>
                            <td style={{ textAlign: "center" }}>{lot.status}</td>
                            <td style={{ textAlign: "center" }}>{lot.couponType}</td>
                            <td style={{ textAlign: "center" }}>{moment(lot.startDate).format('DD/MM/YYYY')}</td>
                            <td style={{ textAlign: "center" }}>{moment(lot.endDate).format('DD/MM/YYYY')}</td>
                            {
                              role &&
                              <td style={{ textAlign: "center" }}>
                                <div>
                                  {/* <CButton color="secondary" onClick={() => handleShowCouponsOfLot(lot)} >View</CButton> */}
                                  <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => handleEditLot(lot._id, lot)}>Update</CButton>
                                  <CButton style={{ marginLeft: '10px', backgroundColor: '#ab2f0c', color: 'white' }} onClick={() => handleDeleteLot(lot._id)}>Delete</CButton>

                                </div>
                              </td>
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>

                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClickLot}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={offset}
                  />

                  <CModal
                    backdrop="static"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    alignment="center"
                    aria-labelledby="StaticBackdropExampleLabel"
                  >
                    <CModalHeader>
                      <CModalTitle id="StaticBackdropExampleLabel">Update Lot</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      <CForm>
                        <CRow>
                          <CCol>
                            <CFormLabel htmlFor="autoSizingSelect">Select Status</CFormLabel>
                            <CFormSelect id="autoSizingSelect" value={updatedStatus} onChange={handleStatusChange}>
                              <option value="Enabled">Enabled</option>
                              <option value="Disabled">Disabled</option>
                            </CFormSelect>
                          </CCol>
                          <CCol>
                            <CFormLabel htmlFor="endDatePicker">Select End Date</CFormLabel>
                            <CFormInput
                              id="endDatePicker"
                              type="date"
                              aria-describedby="exampleFormControlInputHelpInline"
                              value={endDateValue}
                              onChange={handleEndDateChange}
                            />
                          </CCol>
                        </CRow>

                      </CForm>
                    </CModalBody>
                    <CModalFooter>
                      <CButton color="secondary" onClick={closeUpdateModal}>
                        Close
                      </CButton>
                      <CButton color="success" onClick={updateLot}>Save changes</CButton>
                    </CModalFooter>
                  </CModal>

                  <CModal
                    visible={deleteVisible}
                    backdrop="static"
                    onClose={closeDeleteModel}
                    alignment="center"
                    aria-labelledby="StaticBackdropExampleLabel"
                  >
                    <CModalHeader>
                      <CModalTitle id="StaticBackdropExampleLabel">Delete Confirmation</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Are you sure you want to delete this lot?</span>
                    </CModalBody>
                    <CModalFooter>
                      <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => { deleteLotData() }}>Yes</CButton>
                      <CButton style={{ backgroundColor: '#ab2f0c', color: 'white' }} onClick={closeDeleteModel}>
                        No
                      </CButton>
                    </CModalFooter>
                  </CModal>
                </>}

            </CCardBody>
          )
            :
            (
              <CCard>
                {viewPage &&
                  <>
                    <CCardHeader className="d-flex justify-content-between align-items-center">
                      <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setViewPage('')}>Back</CButton>
                      <span style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', flex: 1 }}>View {viewPage} coupons</span>
                    </CCardHeader>
                  </>
                }
                <CCardBody>
                  {!viewPage &&
                    <CRow>
                      <h5>Total Lot count: <span ><b>{totalCount}</b></span></h5>
                    </CRow>
                  }
                  {!viewPage &&
                    <>
                      <CRow >
                        <CCol md={6} style={{ marginTop: '1%', marginBottom: '1%' }}>

                          <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                            <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                              <CIcon icon={cilSearch} size="xl" />
                            </div>

                            <CFormInput
                              placeholder="Search..."
                              autoComplete="Search"
                              style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                              onChange={handleCouponSearch}
                            />
                          </div>
                        </CCol>
                        <CCol md={4} style={{ marginTop: '1%', marginBottom: '1%' }}></CCol>
                        <CCol md={2} style={{ marginTop: '1%', marginBottom: '1%' }}>
                          <div style={{ marginTop: '-1%', marginBottom: '1%' }}>
                            <ExcelFile data={handleCouponExcelFile} />
                          </div>
                        </CCol>
                      </CRow>
                      {loading && <LoadingScreen></LoadingScreen>}

                      {!loading &&
                        <>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }}>Sr. No</th>
                                <th style={{ textAlign: "center" }}>Lot Name</th>
                                <th style={{ textAlign: "center" }}>Type</th>
                                <th style={{ textAlign: "center" }}>Total Coupons</th>
                                <th style={{ textAlign: "center" }}>Used Coupons</th>
                              </tr>
                            </thead>
                            <tbody>
                              {usedCouponData &&
                                usedCouponData.map((row, index) => (
                                  <tr key={index}>
                                    <td style={{ textAlign: "center" }}>{offset * 10 + (index + 1)}</td>
                                    <td style={{ textAlign: "center" }}>{row.lotName}</td>
                                    <td style={{ textAlign: "center" }}>{row.couponType}</td>
                                    <td style={{ textAlign: "center" }}>{row.coupon.length}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <CButton onClick={() => handleViewUsedCoupons(row.coupon)} style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }}>View coupons</CButton>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>

                          <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={offset}
                          />
                        </>
                      }
                    </>
                  }

                  {viewPage == 'Used' &&
                    <>
                      <CRow>
                        <h6>Coupon Count: <span ><b>{viewUsedCouponData ? viewUsedCouponData.length : 0}</b></span></h6>
                      </CRow>
                      <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                          <CIcon icon={cilSearch} size="xl" />
                        </div>

                        <CFormInput
                          placeholder="Search..."
                          autoComplete="Search"
                          style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                          onChange={(e) => handleViewCouponSearch(e.target.value)}
                        />
                      </div><br />
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>Sr. No</th>
                            <th style={{ textAlign: "center" }}>Coupon Code</th>
                            <th style={{ textAlign: "center" }}>Coupon Type</th>
                            <th style={{ textAlign: "center" }}>Course Name</th>
                            <th style={{ textAlign: "center" }}>Student Name</th>
                            {activeKey === 1 && <th style={{ textAlign: "center" }}>Student Name</th>}
                            <th style={{ textAlign: "center" }}>Coupon Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData.flatMap((row, index) => {
                            const isUniversal = row.couponType.toLowerCase() === "universal";

                            // If coupon type is universal, create a row for each user
                            if (isUniversal && row.usedBy.length > 0) {
                              return row.usedBy.map((user, userIndex) => (
                                <tr key={`${index}-${userIndex}`}>
                                  <td style={{ textAlign: "center" }}>
                                    {currentPage * itemsPerPage + index + 1}
                                  </td>
                                  <td style={{ textAlign: "center" }}>{row.couponCode}</td>
                                  <td style={{ textAlign: "center" }}>{row.couponType}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.courses.length > 0 ? row.courses.map(cou => JSON.parse(cou).name).join(", ") : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>{JSON.parse(user).name}</td>
                                  {activeKey === 1 && <td style={{ textAlign: "center" }}>{JSON.parse(user).name}</td>}
                                  <td style={{ textAlign: "center" }}>{row.amount}</td>
                                </tr>
                              ));
                            } else {
                              // If not universal, show one row
                              return (
                                <tr key={index}>
                                  <td style={{ textAlign: "center" }}>
                                    {currentPage * itemsPerPage + index + 1}
                                  </td>
                                  <td style={{ textAlign: "center" }}>{row.couponCode}</td>
                                  <td style={{ textAlign: "center" }}>{row.couponType}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.courses.length > 0 ? row.courses.map(cou => JSON.parse(cou).name).join(", ") : ""}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {row.usedBy.length > 0 ? row.usedBy.map(ubs => JSON.parse(ubs).name).join(", ") : "Coupon Not Used"}
                                  </td>
                                  {activeKey === 1 && <td style={{ textAlign: "center" }}>{row.usedBy.length > 0 ? row.usedBy.map(user => JSON.parse(user).name).join(", ") : "-"}</td>}
                                  <td style={{ textAlign: "center" }}>{row.amount}</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>

                      </Table>
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(viewUsedCouponData.length / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClickCoupon}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />

                    </>
                  }

                </CCardBody>
              </CCard>
            )
          }

          {
            toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
          }

          {
            deleteDailog && <DeleteDailog
              visible={deleteDailog}
              onClose={() => setDeleteDialog(false)}
              delete={handleDeleteCoupon}
            ></DeleteDailog>
          }

          {
            usedCouponDeleteDailog && <DeleteDailog
              visible={usedCouponDeleteDailog}
              onClose={() => setUsedCouponDeleteDialog(false)}
              delete={handleUsedCouponDeleteCoupon}
            ></DeleteDailog>
          }

        </CCard >
      }

    </>
  )
}

export default CouponCodeGenerate

import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Box,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import userApi from "src/utils/axios";
import ConfigData from "./../../../../../config/constant.json";

const BatchForum = ({ Batch_id }) => {
  const [topics, setTopics] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Fetch topics from the backend
  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      setLoading(true);
      const response = await userApi.get(
        `${ConfigData.SERVER_URL}/admin/quiz/getTopics`,
        { params: { workshopId: Batch_id } }
      );
      setTopics(response.data.topics);
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddComment = async (forumId) => {
    if (!selectedComment) return;
    try {
      await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/addComment`, {
        forumId,
        comment: selectedComment,
      });
      setSelectedComment("");
      fetchTopics(); // Refresh comments
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!commentToDelete) return;
    try {
      await userApi.delete(
        `${ConfigData.SERVER_URL}/admin/quiz/deleteComment`,
        { params: { commentId } }
      );
      setCommentToDelete(null);
      setDeleteDialogOpen(false);
      fetchTopics(); // Refresh comments
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };
  const openDeleteDialog = (commentId) => {
    setCommentToDelete(commentId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCommentToDelete(null);
  };

  const openFileModal = (fileName, ext) => {
    const sanitizedUrl = `${ConfigData.SERVER_URL}/topicFiles/${fileName}.${ext}`;
    setSelectedFile(sanitizedUrl);
    setFileModalOpen(true);
  };

  const closeFileModal = () => {
    setFileModalOpen(false);
    setSelectedFile(null);
  };

  const getSanitizedUrl = (fileName, ext) => {
    if (fileName && ext) {
      return `${ConfigData.FILE_STORAGE_LOCATION}/ProfileImages/${fileName}.${ext}`;
    } else {
      return "/path-to-default-avatar/default-avatar.png"; // Replace with your default avatar path
    }
  };

  return (
    <Box sx={{ padding: "20px", backgroundColor: "#f7f9fc" }}>
      {/* File Modal */}
      <Dialog
        open={fileModalOpen}
        onClose={closeFileModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Uploaded File</DialogTitle>
        <DialogContent>
          {selectedFile ? (
            selectedFile.endsWith(".pdf") ? (
              <iframe
                src={selectedFile}
                title="Uploaded File"
                style={{ width: "100%", height: "500px", border: "none" }}
              ></iframe>
            ) : (
              <img
                src={selectedFile}
                alt="Uploaded File"
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "contain",
                }}
              />
            )
          ) : (
            <Typography>No file selected.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFileModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteComment(commentToDelete)}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          marginBottom: "20px",
          color: "#333",
          textAlign: "center",
        }}
      >
        Forum
      </Typography>
      {loading ? (
        <Typography>Loading topics...</Typography>
      ) : topics.length === 0 ? (
        <Typography>No topics available.</Typography>
      ) : (
        topics.map((topic) => (
          <Accordion
            key={topic._id}
            disableGutters
            sx={{
              boxShadow: "none",
              borderBottom: "1px solid #ccc",
              backgroundColor: "#fff",
              "&:before": { display: "none" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: "#f1f1f1",
                border: "1px solid #e0e0e0",
                "& .MuiTypography-root": {
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "#333",
                },
              }}
            >
              <Typography>{topic.topicName}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: "#f9f9f9", padding: "20px" }}
            >
              <Typography
                variant="body1"
                sx={{ marginBottom: "10px", color: "#555", fontSize: "1rem" }}
              >
                {topic.description}
              </Typography>
              {topic.fileName && (
                <Box display="flex" alignItems="center" marginTop="10px">
                  <Typography variant="body2" color="#333" fontSize=" 1rem">
                    {/* {topic.fileName.split('/').pop()}  */}
                    Uploaded File :-
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      openFileModal(topic.fileName, topic.extension)
                    }
                    sx={{ marginLeft: "10px" }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
              )}
              <Divider sx={{ margin: "15px 0" }} />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "#333",
                  marginBottom: "10px",
                }}
              >
                Comments
              </Typography>
              <List>
                {topic.forumComments.map((comment) => (
                  <ListItem
                    key={comment._id}
                    alignItems="flex-start"
                    sx={{
                      padding: "10px 0",
                      borderBottom: "1px solid #eee",
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                    }}
                  >
                    <Avatar
                      src={
                        comment.userDetails?.fileName
                          ? getSanitizedUrl(
                              comment.userDetails.fileName,
                              comment.userDetails.extension
                            ) // Use actual file extension
                          : null
                      }
                      alt={comment.userDetails?.fullName || "Default Avatar"}
                      sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#1976d2",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {!comment.userDetails?.fileNmae &&
                        (comment.userDetails?.fullName
                          ? comment.userDetails.fullName[0].toUpperCase()
                          : "")}
                    </Avatar>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#333",
                          fontSize: "0.95rem",
                        }}
                      >
                        {comment.userDetails?.fullName ||
                          (comment.userDetails?.firstName &&
                          comment.userDetails?.lastName
                            ? comment.userDetails.firstName +
                              " " +
                              comment.userDetails.lastName
                            : comment.userDetails?.name)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          color: "#555",
                          margin: "5px 0",
                        }}
                      >
                        {comment.comment}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          color: "#888",
                          // marginBottom: '5px',
                        }}
                      >
                        {new Date(comment.createdAt).toLocaleString()}
                      </Typography>
                    </Box>
                    <ListItemSecondaryAction>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => openDeleteDialog(comment._id)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Divider sx={{ margin: "15px 0" }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Avatar
                  sx={{ width: 40, height: 40, backgroundColor: "#1976d2" }}
                ></Avatar>
                <TextField
                  fullWidth
                  variant="standard" 
                  size="small"
                  placeholder="Add a comment..." 
                  value={selectedComment}
                  onChange={(e) => setSelectedComment(e.target.value)}
                  InputProps={{
                    disableUnderline: false,
                  }}
                  sx={{
                    fontSize: "14px",
                  }}
                />

                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleAddComment(topic._id)}
                  sx={{
                    textTransform: "none",
                    fontSize: "0.85rem",
                  }}
                >
                  Comment
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default BatchForum;
